import * as React from "react";
import { Apparel, Layout, PageTitle } from "../components";
import Seo from "../components/Seo";

const ApparelPage = () => {
  return (
    <Layout>
      <Seo title="APPAREL - emo TOKYO Apparelについて" />
      <PageTitle
        title="APPAREL"
        subTitle="emo TOKYOが考える新時代のストリートファッション"
      />
      <Apparel />
    </Layout>
  );
};

export default ApparelPage;
